import React from 'react';
import './MainLayout.css'; // Ensure this file has the flame CSS

const Flame = () => {
  return (
    <div className="flames-container">
      <div className="flames"></div>
    </div>
  );
};

export default Flame;