import React from 'react';
import Header from './Header'; // Importing the Header component
import ContactForm from './ContactForm';

const Learn = () => {
  return (
    <div className="bg-primary min-h-screen dark:bg-gray-900 dark:text-white transition duration-500">
      <Header />
      <main>
        <section id="learn" className="container mx-auto p-4">
          <h1 className="text-4xl mb-8">Learn</h1>
          {/* Add your Learn section content here */}
          <p>Welcome to the Learn page. Here you can find various tutorials and resources.</p>
        </section>
        <section id="contact" className="w-full bg-white">
          <ContactForm />
        </section>
      </main>
    </div>
  );
};

export default Learn;