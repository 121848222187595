import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import PixelBut from './PixelBut';
import PixelInput from './PixelInput';
import PixelInputL from './PixelInputL';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_bwl1tas', 'template_63sgsit', e.target, 'ejXbVsIgYFe7HEqXa')
      .then((result) => {
        console.log(result.text);
        setSuccess(true);
        setError(false);
        setName('');
        setEmail('');
        setMessage('');
      }, (error) => {
        console.log(error.text);
        setSuccess(false);
        setError(true);
      });
  };

  return (
    <div className="contact-form-container bg-css bg-opacity relative text-white p-6 md:p-12">
      <form onSubmit={handleSubmit} className="container mx-auto contact-form grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-4 mb-8">
        <div className="col-span-1">
          <div className="flex flex-col md:flex-row items-center justify-end">
            <label htmlFor="name" className="font-pstwo mr-6 md:mr-4 text-right w-full md:w-auto">What is thy name</label>
            <PixelInput>
              <input
                className="p-8 text-primary bg-transparent border-none w-full"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </PixelInput>
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="flex flex-col md:flex-row items-center justify-end">
            <label htmlFor="email" className="font-pstwo mr-6 md:mr-4 text-right w-full md:w-auto">Leave thine email</label>
            <PixelInput>
              <input
                className="p-8 text-primary bg-transparent border-none w-full"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </PixelInput>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col md:flex-row items-center justify-end">
            <label htmlFor="message" className="font-pstwo mr-6 md:mr-4 text-right w-full md:w-auto">Unfurl your quill and compose</label>
            <PixelInputL>
              <textarea
                className="pl-8 pr-8 text-primary bg-transparent border-none w-full h-28"
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </PixelInputL>
          </div>
        </div>
        <div className="col-span-1 flex justify-end">
          <PixelBut>
            <button type="submit" className="w-full md:w-96 h-full bg-transparent text-black">Send</button>
          </PixelBut>
        </div>
      </form>
      {success && <p className="success-message">Your message has been sent successfully!</p>}
      {error && <p className="error-message">There was an error sending your message. Please try again later.</p>}
    </div>
  );
};

export default ContactForm;
