import React, { useState, useEffect } from 'react';
import headerImage from '../img/headerc_bg.png';
import headerDivider from '../img/menu_divider.png';
import PixelFlame from './PixelFlame';
import PixelIce from './PixelIce';
import PixelSword from './PixelSword';
import './Header.css';
import { Link as ScrollLink } from 'react-scroll';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

const webBlocks = [
  { component: <PixelFlame />, text: 'CSS' },
  { component: <PixelSword />, text: 'JAVASCRIPT' },
  { component: <PixelIce />, text: 'HTML' },
];

const Header = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeTransition, setFadeTransition] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeTransition(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % webBlocks.length);
        setFadeTransition(false);
      }, 1000); // This timeout should match the CSS transition duration
    }, 5000); // Change the interval time to adjust the speed (4 seconds total: 3 seconds visible, 1 second transition)

    return () => clearInterval(interval);
  }, []);

  const handleNavigateToSection = (section) => {
    if (location.pathname !== '/') {
      navigate(`/#${section}`);
    } else {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        window.scrollTo({
          top: sectionElement.offsetTop - 70,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <header className="relative h-96 bg-gray-800 text-white custom-header">
      {/* Navigation Bar */}
      <nav className="fixed top-0 left-0 w-full z-30 bg-navcolor">
        <div className="container mx-auto flex justify-between items-center p-4">
          <div className="text-xl font-bold">Gabz Rêveur</div>
          <div className="space-x-4 flex">
            <NavLink to="/" className="text-white hover:text-gray-300">Home</NavLink>
            <span
              className="text-white hover:text-gray-300 cursor-pointer"
              onClick={() => handleNavigateToSection('about')}
            >
              About
            </span>
            <span
              className="text-white hover:text-gray-300 cursor-pointer"
              onClick={() => handleNavigateToSection('portfolio')}
            >
              Portfolio
            </span>
            <NavLink to="/movies" className="text-white hover:text-gray-300">Movies</NavLink>
            <NavLink to="/learn" className="text-white hover:text-gray-300">Learn</NavLink>
            <ScrollLink to="contact" spy={true} smooth={true} offset={-70} duration={700} className="text-white hover:text-gray-300 cursor-pointer">Contact</ScrollLink>
          </div>
        </div>
      </nav>

      {/* Divider Image */}
      <div
        className="fixed top-10 left-0 w-full h-10 bg-contain bg-no-repeat z-20"
        style={{
          backgroundImage: `url(${headerDivider})`,
          backgroundSize: 'cover',
        }}
      ></div>

      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-top z-10"
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="dark:bg-gray-800 pr-56 rounded-lg shadow-lg mx-auto font-pstwo text-4xl text-white opacity-60 text-center z-40"></div>

        {/* Overlay for text visibility */}
        <div className="absolute inset-0 bg-black opacity-10"></div>

        {/* Web Blocks */}
        <div className="grid grid-cols-1 gap-4 mb-8 pt-44">
          <div className={`dark:bg-gray-800 p-12 rounded-lg mx-auto font-pstwo text-4xl text-white opacity-60 text-center transition-opacity duration-1000 ${fadeTransition ? 'opacity-0' : 'opacity-60'}`}>
            <div className='p-8'>{webBlocks[currentIndex].component}</div> {webBlocks[currentIndex].text}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="container mx-auto h-full relative flex flex-col top-12 right-0 justify-top items-end p-20">
        <h1 className="text-primary text-3xl font-bold z-10 font-kreon opacity-70">Ama, Aprende y Crece siempre!</h1>
        <h2 className="text-primary text-2xl font-normal z-10 font-kreon opacity-50">- Gabz Rêveur</h2>
      </div>
    </header>
  );
};

export default Header;
