import React from 'react';

const PixelInputL = ({ children }) => (
  <div className="relative inline-block">
    <svg xmlns="http://www.w3.org/2000/svg" width="375" height="214" viewBox="0 0 575 214" fill="none">
<path d="M19.5 10V20H10V28.5H1V176.5H10V186.5H19.5V195.5H29V205H37.5V213.5H547V204H556.5V194.5H566V185.5H574.5V38H566V29.5H556.5V20.5H547V10.5H537.5V1H29V10H19.5Z" fill="black" stroke="black"/>
<path d="M10.5 176V29.5H19V18.5H28V8.5L538.5 10V19H548V29.5H557.5V176H548V186H538.5V195.5H28V186H19V176H10.5Z" fill="white" stroke="black"/>
</svg>
    <div className="absolute inset-0 flex items-center justify-center">
      {children}
    </div>
  </div>
);

export default PixelInputL;
