import React from 'react';

const PixelInput = ({ children }) => (
  <div className="relative inline-block">
    <svg xmlns="http://www.w3.org/2000/svg" width="375" height="112" viewBox="0 0 575 112" fill="none">
      <path d="M19.5 10V20H10V28.5H1V74.5H10V84.5H19.5V93.5H29V103H37.5V111.5H547V102H556.5V92.5H566V83.5H574.5V38H566V29.5H556.5V20.5H547V10.5H537.5V1H29V10H19.5Z" fill="black" stroke="black"/>
      <path d="M10.5 74.5V29.5H19V18.5H28V8.5L538.5 10V19H548V29.5H557.5V74.5H548V84.5H538.5V94H28V84.5H19V74.5H10.5Z" fill="white" stroke="black"/>
    </svg>
    <div className="absolute inset-0 flex items-center justify-center">
      {children}
    </div>
  </div>
);

export default PixelInput;
