import React, { useState, useEffect } from 'react';
import Header from './Header'; // Importing the Header component
import About from './About';
import Portfolio from './Portfolio';
import ContactForm from './ContactForm';


import './MainLayout.css'; // Import the CSS file for the transition styles

const backgroundImageUrl = 'https://media.licdn.com/dms/image/C4E03AQGcOrPnA7y_BQ/profile-displayphoto-shrink_200_200/0/1518636456403?e=1725494400&v=beta&t=jyWkyuKJ95kBOzh2LR8VAJqucwTGmVtiMJRvTxsZy30';

const MainLayout = () => {


  return (
    <div className="bg-primary min-h-screen dark:bg-gray-900 dark:text-white transition duration-500">
      <Header />
      <main   >    
        <section id="about" className="container mx-auto p-4">  
            <About />
        </section>  
        <section id="portfolio" className="w-full bg-white">  
            <Portfolio />
        </section>
          <section id="contact" className="w-full bg-white">  
            <ContactForm />
        </section>


      </main>
        
        
    </div>
    
  );
};

export default MainLayout;
