import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PixelFlag from './PixelFlag';
import './Portfolio.css'; // Import custom CSS for the progress bar
import ooyala from '../img/ooyala-af.png'; // Import portfolio image
import jabil from '../img/jabilweb.png'; // Import portfolio image
import amelie from '../img/amelie-memory.png'; // Import portfolio image
import pathfinder from '../img/pathfinder-sidepanels.png'; // Import portfolio image
import byg from '../img/byg.png'; // Import portfolio image


const Portfolio = () => {
  const autoplaySpeed = 10000; // 6 seconds
  const [progress, setProgress] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    beforeChange: () => setProgress(0), // Reset progress before slide change
    prevArrow: null, // Remove default previous arrow
    nextArrow: null, // Remove default next arrow
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + (100 / (autoplaySpeed / 100)) : 0));
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto bg-opacity-100 py-8 relative">
        <Slider {...settings}>

        <div className="p-12 ">
                <h2 className="text-3xl p-2 font-pstwo text-center" >Pokemon Kids Memory Game</h2>
                <div className=" grid grid-cols-1 md:grid-cols-[8fr,4fr] dark:bg-gray-800 p-12 rounded-lg   text-4xl text-center">
                    <div className="text-lg text-left mt-4 ">
                        <ul className="text-lg text-left mt-4 space-y-2 list-disc list-inside">
                            <li className="text-opacity-60"><span className="font-bold">Conceptualized a Pokémon-themed game :</span> specifically designed to engage and educate young players, ideal for your 5-year-old daughter.</li>
                            <li className="text-opacity-60"><span className="font-bold">Leveraged Pokémon APIs</span> to meticulously craft visually captivating game characters that spark children's imaginations.</li>
                            <li className="text-opacity-60"><span className="font-bold">Implemented Age-Appropriate Logic Structures</span> to create a stimulating and rewarding gameplay experience that fosters memory improvement in a fun and engaging way.</li>

                            
                        </ul>
                    
                        <div className=' text-right font-bold text-html '><a href="http://www.gabzreveur.com/js/apps/memory" target="_blank">Click here to play --> </a></div>
                    </div>
                    <div className="">
                        <PixelFlag imageUrl={amelie} />
                    </div>
                </div>
            </div>



            <div className="p-12 ">
                <h2 className="text-3xl p-2 font-pstwo text-center" >JABIL</h2>
                <div className=" grid grid-cols-1 md:grid-cols-[8fr,4fr] dark:bg-gray-800 p-12 rounded-lg   text-4xl text-center">
                    <div className="text-lg text-left mt-4 ">
                        <ul className="text-lg text-left mt-4 space-y-2 list-disc list-inside">
                            <li className="text-opacity-60"><span className="font-bold">Redesigned Jabil's internal web portal for over 10,000 employees</span>, ensuring a user-friendly and visually appealing experience.</li>
                            <li className="text-opacity-60"><span className="font-bold">Overcame SharePoint limitations</span> to implement the design team's vision while maintaining functionality.</li>
                            <li className="text-opacity-60"><span className="font-bold">Enhanced user experience</span> by:
                            <ul className="list-disc list-inside ml-6 space-y-2">
                                <li className="text-opacity-60">Implementing a dynamic Quick Links section using JavaScript elements.</li>
                                <li className="text-opacity-60">Supporting three languages (English, Spanish, Chinese) for a global audience.</li>
                                <li className="text-opacity-60">Integrating jQuery effects for improved interactivity within the portal.</li>
                            </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <PixelFlag imageUrl={jabil} />
                    </div>
                </div>
            </div>

            <div className="p-12 ">
                <h2 className="text-3xl p-2 font-pstwo text-center" >MICROSOFT / ICALIA</h2>
                <div className=" grid grid-cols-1 md:grid-cols-[8fr,4fr] dark:bg-gray-800 p-12 rounded-lg   text-4xl text-center">
                    <div className="text-lg text-left mt-4 ">
                        <ul className="text-lg text-left mt-4 space-y-2 list-disc list-inside">
                            <li className="text-opacity-60"><span className="font-bold">Enhanced User Experience</span>: Developed a custom side panel leveraging JavaScript logic. This empowers users to personalize their view of the model-driven Power App by adjusting the visibility and configuration of built-in panels, leading to a more focused and efficient user experience.</li>
                            <li className="text-opacity-60"><span className="font-bold">Improved Efficiency</span>: Built a custom form integrated with the model-driven Power App. This form retrieves relevant data from designated tables and automates email communication to specific users based on the data. This eliminates manual tasks and streamlines communication workflows.</li>
                            <li className="text-opacity-60"><span className="font-bold">Dynamic Data Management</span>: Created a new form integrated with a custom Power Automate flow. This innovative approach retrieves data dynamically from the flow and adjusts form field properties based on the flow's logic, ensuring real-time data accuracy and tailored user interactions within the model-driven Power App.</li>
                        </ul>
                    </div>
                    <div className="">
                        <PixelFlag imageUrl={pathfinder} />
                    </div>
                </div>
            </div>

            <div className="p-12 ">
                <h2 className="text-3xl p-2 font-pstwo text-center" >OOYALA</h2>
                <div className=" grid grid-cols-1 md:grid-cols-[6fr,6fr] dark:bg-gray-800 p-12 rounded-lg   text-4xl text-center">
                    <div className="text-lg text-left mt-4 ">
                        <ul className="text-lg text-left mt-4 space-y-2 list-disc list-inside">
                            <li className="text-opacity-60"><span className="font-bold">Drupal Migration & Upgrade</span>: Successfully migrated Ooyala's Drupal website from an older version to the latest stable release, ensuring improved performance and security.</li>
                            <li className="text-opacity-60"><span className="font-bold">Website Redesign</span>: Collaborated with the creative team to implement a new, clean, and functional design for ooyala.com, enhancing user experience.</li>
                            <li className="text-opacity-60"><span className="font-bold">Custom Drupal Theme</span>: Developed a bespoke Drupal theme aligned with Ooyala's brand identity for the new website version.</li>
                            <li className="text-opacity-60"><span className="font-bold">Multilingual Expansion</span>: Created Korean and Spanish versions of the website, adapting all pages and designs for a wider global audience.</li>
                        </ul>
                    </div>
                    <div className="">
                        <PixelFlag imageUrl={ooyala} />
                    </div>
                </div>
            </div>
            <div className="p-12 ">
                <h2 className="text-3xl p-2 font-pstwo text-center" >BYG</h2>
                <div className=" grid grid-cols-1 md:grid-cols-[8fr,4fr] dark:bg-gray-800 p-12 rounded-lg   text-4xl text-center">
                    <div className="text-lg text-left mt-4 ">
                        <ul className="text-lg text-left mt-4 space-y-2 list-disc list-inside">
                            <li className="text-opacity-60"><span className="font-bold">Developed a responsive website using Bootstrap</span> to showcase client work in a clean, user-friendly, and visually appealing manner.</li>
                            <li className="text-opacity-60"><span className="font-bold">Collaborated with a designer</span> to ensure the website effectively communicated BYG.COOL's brand identity.</li>
                            <li className="text-opacity-60"><span className="font-bold">Focused on optimizing website performance and responsiveness</span> to provide a seamless user experience across devices.</li>
                            <li className="text-opacity-60"><span className="font-bold">Contributed to client acquisition</span> by participating in client meetings and collaborating on project requirements and needs.</li>
                        </ul>
                    </div>
                    <div className="">
                        <PixelFlag imageUrl={byg} />
                    </div>
                </div>
            </div>      </Slider>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default Portfolio;
