// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Learn from './components/Learn';
import Movies from './components/Movies';
import './index.css';

function App() {
  return (
    <Router basename="/">
    <Routes>
      <Route path="/" element={<MainLayout />} />
      <Route path="/learn" element={<Learn />} />
      <Route path="/movies" element={<Movies />} />
    </Routes>
  </Router>
  );
}

export default App;