import React from 'react';

const PixelFlag = ({ imageUrl }) => (
  <div className="flex justify-end items-end">
    <svg xmlns="http://www.w3.org/2000/svg" width="524" height="504" viewBox="0 0 212 202" fill="none">
      <defs>
       
        <mask id="mask">
          <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M10 176V29.5H18.5V18.5H27.5V8.5L179 10V19H188.5V29.5H198V176H188.5V186H179V195.5H27.5V186H18.5V176H10Z" />
        </mask>
      </defs>
      <g filter="url(#shadow)">
        <path fill="#000" d="M19.5 10V20H10V28.5H1V176.5H10V186.5H19.5V195.5H29V205H37.5V213.5H187.5V204H197V194.5H206.5V185.5H215V38H206.5V29.5H197V20.5H187.5V10.5H178V1H29V10H19.5Z" />
        <image href={imageUrl} x="0" y="0" height="100%" width="100%" mask="url(#mask)" preserveAspectRatio="xMidYMid slice" />
      </g>
    </svg>
  </div>
);

export default PixelFlag;
